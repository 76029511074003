import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SortingButton from "src/pages/EtherOrdersPage/components/EtherOrdersDataControl/components/SortingButton";
import { carClasses } from "src/shared/constants";
import { useTranslation } from "react-i18next";
import useClickOutside from "src/hooks/useClickOutside";
import WagonInfoPopup from "src/pages/EtherOrdersPage/components/EtherOrdersDataControl/components/WagonInfoPopup";


const CarClassesFilter = ({
  carClassFilterButtonsSet,
  defaultCarClassId,
  activeCarClassIds,
  setActiveCarClassIds
}) => {

  const { t } = useTranslation();

  const [selectedCarClassId, setSelectedCarClassId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useClickOutside(() => setIsPopupVisible(false));
  const [isActiveAllButton, setIsActiveAllButton] = useState(() => {
    const isActive = activeCarClassIds.size === carClassFilterButtonsSet.size;
    return isActive;
  });

  const handleConfirm = () => {
    if (selectedCarClassId) {
      const updatedCarClassIds = new Set(activeCarClassIds);
      updatedCarClassIds.add(selectedCarClassId);
      setActiveCarClassIds(updatedCarClassIds);
      setSelectedCarClassId(null);
    }
    setIsPopupVisible(false);
  };

  const handleCancel = () => {
    setSelectedCarClassId(null);
    setIsPopupVisible(false);
  };


  const handleClickAllButton = () => {
    if (isActiveAllButton) {
      return;
    } else {
      const allClasses = [...carClassFilterButtonsSet]
        .map((button) => button.type)
        .filter((type) => {
          return !activeCarClassIds.has(carClasses.WAGON) ? type !== carClasses.WAGON : true;
        });
      setActiveCarClassIds(new Set(allClasses));
    }
    setIsActiveAllButton((prev) => !prev);
  };

  const handleCarClassFilter = (carClassId) => {
    const updatedCarClassIds = new Set(activeCarClassIds);

    if (carClassId === carClasses.WAGON) {
      if (updatedCarClassIds.has(carClassId)) {
        updatedCarClassIds.delete(carClassId);
      } else {
        setIsPopupVisible(true);
        setSelectedCarClassId(carClassId);
      }
      setActiveCarClassIds(updatedCarClassIds);
      return;
    }

    if (updatedCarClassIds.has(carClassId) && updatedCarClassIds.size === (updatedCarClassIds.has(carClasses.WAGON) ? 2 : 1)) {
      return;
    }

    if (updatedCarClassIds.has(carClassId)) {
      updatedCarClassIds.delete(carClassId);
    } else {
      updatedCarClassIds.add(carClassId);
    }

    setIsActiveAllButton(
      () => {
        if (updatedCarClassIds.has(carClasses.WAGON)) {
          return updatedCarClassIds.size === carClassFilterButtonsSet.size;
        }
        return updatedCarClassIds.size === carClassFilterButtonsSet.size - 1;
      });

    setActiveCarClassIds(updatedCarClassIds);
  };

  useEffect(() => {
    const validIds = new Set([...carClassFilterButtonsSet].map((button) => button.type));
    const updatedSet = new Set([...activeCarClassIds].filter((id) => validIds.has(id)));
    setActiveCarClassIds(updatedSet);
  }, []);

  useEffect(() => {
    setIsActiveAllButton(() => {
      if (activeCarClassIds.has(carClasses.WAGON)) {
        return activeCarClassIds.size === carClassFilterButtonsSet.size;
      }
      return activeCarClassIds.size === carClassFilterButtonsSet.size - 1;
    });
  }, [activeCarClassIds, carClassFilterButtonsSet]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          {defaultCarClassId !== carClasses.STANDARD && (
            <SortingButton
              active={isActiveAllButton}
              key="All"
              onClick={handleClickAllButton}
              size="filterStyle"
            >
              {t("all")}
            </SortingButton>
          )}
          {[...carClassFilterButtonsSet].map((buttonProps) => (
            <SortingButton
              key={buttonProps.type}
              active={activeCarClassIds.has(buttonProps.type)}
              onClick={() => handleCarClassFilter(buttonProps.type)}
              backgroundClass={buttonProps.backgroundClass}
              size="filterStyle"
            >
              {t(buttonProps.label)}
            </SortingButton>
          ))}
        </div>
      </div>
      {isPopupVisible &&
        <WagonInfoPopup
          showPopup={isPopupVisible}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          ref={popupRef}
        />}
    </>
  );
};

export default CarClassesFilter;
