import { useEffect, useState } from "react";
import { ReactComponent as DistanceIcon } from "src/images/distance-icon.svg";
import { ReactComponent as DistanceIconDown } from "src/images/distance-icon-down.svg";
import { ReactComponent as BestOrderIcon } from "src/images/best-order.svg";
import { ReactComponent as MoneyCard } from "src/images/money-card.svg";
import { ReactComponent as MoneyCash } from "src/images/money-cash.svg";
import { ReactComponent as PriceIcon } from "src/images/price-icon.svg";
import { ReactComponent as PriceIconUp } from "src/images/price-icon-up.svg";
import SortingButton from "./components/SortingButton";
import styles from "./styles.module.scss";
import { classNames } from "src/shared/lib/classNames/classNames";
import { etherSortOptions, carClasses } from "src/shared/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WagonInfoPopup from "./components/WagonInfoPopup";
import useClickOutside from "src/hooks/useClickOutside";

/*  const sortingButtonsData = [
  { type: etherSortOptions.ALL, Icon: null, label: "all" },
  { type: etherSortOptions.CASH, Icon: MoneyCash, label: "cash" },
  { type: etherSortOptions.CARD, Icon: MoneyCard, label: "card" },
];  */

const carClassFilterButtonsSet = new Set([
  { type: carClasses.STANDARD, label: "standard", backgroundClass: 'economyHighlighting' },
  { type: carClasses.COMFORT, label: "comfort", backgroundClass: 'standardHighlighting' },
  { type: carClasses.BUSINESS, label: "business", backgroundClass: 'comfortHighlighting' },
  { type: carClasses.MINIVAN, label: "minivan", backgroundClass: 'minivanHighlighting' },
  { type: carClasses.WAGON, label: "wagon", backgroundClass: 'wagonHighlighting' },
]);


const getFilteredCarClassButtonsSet = (defaultCarClassId) => {
  const exclusions = {
    [carClasses.STANDARD]: new Set([carClasses.COMFORT, carClasses.BUSINESS, carClasses.MINIVAN]),
    [carClasses.COMFORT]: new Set([carClasses.BUSINESS, carClasses.MINIVAN]),
    [carClasses.BUSINESS]: new Set([carClasses.MINIVAN]),
    [carClasses.MINIVAN]: new Set([carClasses.BUSINESS]),
  };

  return new Set(
    [...carClassFilterButtonsSet].filter(
      (button) => !exclusions[defaultCarClassId]?.has(button.type)
    )
  );
};

const EtherOrdersDataControl = ({
  setFilterType,
  setActiveCarClassIds,
  setSortType,
  filterType,
  activeCarClassIds,
  sortType,
}) => {

  const { t } = useTranslation();
  const avtos = useSelector((state) => state.cars.avtos);
  const defaultCar = avtos.find((car) => car.isDefault);
  const defaultCarClassId = defaultCar ? defaultCar.classId : carClasses.STANDARD;
  const filteredCarClassFilterButtonsSet = getFilteredCarClassButtonsSet(defaultCarClassId);

  const [isActiveAllButton, setIsActiveAllButton] = useState(() => {
    if (activeCarClassIds.has(carClasses.WAGON)) {
      return activeCarClassIds.size === filteredCarClassFilterButtonsSet.size;
    }
    return activeCarClassIds.size === filteredCarClassFilterButtonsSet.size - 1;
  });

  const [selectedCarClassId, setSelectedCarClassId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useClickOutside(() => setIsPopupVisible(false));

  /*      const handleFilter = (filter) => {
        setFilterType(filter);
      };  */

  const handleSort = (sort) => {
    const sortOrder = sort === "efficiency"
      ? "desc"
      : sortType.sortOrder === "asc"
        ? "desc"
        : "asc";

    setSortType({ sortBy: sort, sortOrder });
  };

  const handleConfirm = () => {
    if (selectedCarClassId) {
      const updatedCarClassIds = new Set(activeCarClassIds);
      updatedCarClassIds.add(selectedCarClassId);
      setActiveCarClassIds(updatedCarClassIds);
      setSelectedCarClassId(null);
    }
    setIsPopupVisible(false);
  };

  const handleCancel = () => {
    setSelectedCarClassId(null);
    setIsPopupVisible(false);
  };

  const handleClickAllButton = () => {
    if (isActiveAllButton) {
      return;
    } else {
      const allClasses = [...filteredCarClassFilterButtonsSet]
        .map((button) => button.type)
        .filter((type) => {
          return !activeCarClassIds.has(carClasses.WAGON) ? type !== carClasses.WAGON : true;
        });

      setActiveCarClassIds(new Set(allClasses));
    }
    setIsActiveAllButton((prev) => !prev);
  };


  const handleCarClassFilter = (carClassId) => {

    const updatedCarClassIds = new Set(activeCarClassIds);

    if (carClassId === carClasses.WAGON) {
      if (updatedCarClassIds.has(carClassId)) {
        updatedCarClassIds.delete(carClassId);
      } else {
        setIsPopupVisible(true);
        setSelectedCarClassId(carClassId);
      }
      setActiveCarClassIds(updatedCarClassIds);
      return;
    }

    if (updatedCarClassIds.has(carClassId) && updatedCarClassIds.size === (updatedCarClassIds.has(carClasses.WAGON) ? 2 : 1)) {
      return;
    }

    if (updatedCarClassIds.has(carClassId)) {
      updatedCarClassIds.delete(carClassId);
    } else {
      updatedCarClassIds.add(carClassId);
    }

    setIsActiveAllButton(
      () => {
        if (updatedCarClassIds.has(carClasses.WAGON)) {
          return updatedCarClassIds.size === filteredCarClassFilterButtonsSet.size;
        }
        return updatedCarClassIds.size === filteredCarClassFilterButtonsSet.size - 1;
      });

    setActiveCarClassIds(updatedCarClassIds);
  };

  useEffect(() => {
    const validIds = new Set([...filteredCarClassFilterButtonsSet].map((button) => button.type));
    const updatedSet = new Set([...activeCarClassIds].filter((id) => validIds.has(id)));
    setActiveCarClassIds(updatedSet);
  }, []);

  useEffect(() => {
    const updateLocalStorage = () => {
      const dataToSave = {
        filterType,
        activeCarClassIds: Array.from(activeCarClassIds),
        sortType,
      };
      localStorage.setItem("orderEtherFilters", JSON.stringify(dataToSave));
    };
    updateLocalStorage();
    setIsActiveAllButton(() => {
      if (activeCarClassIds.has(carClasses.WAGON)) {
        return activeCarClassIds.size === filteredCarClassFilterButtonsSet.size;
      }
      return activeCarClassIds.size === filteredCarClassFilterButtonsSet.size - 1;
    });
  }, [filterType, activeCarClassIds, sortType]);


  return (
    <>
      <div className={styles.sortingClassCar}>
        {defaultCarClassId !== carClasses.STANDARD && (
          <SortingButton
            active={isActiveAllButton}
            key="All"
            onClick={handleClickAllButton}
          >
            {t("all")}
          </SortingButton>
        )}
        {[...filteredCarClassFilterButtonsSet].map((buttonProps) => (
          <SortingButton
            key={buttonProps.type}
            active={activeCarClassIds.has(buttonProps.type)}
            onClick={() => handleCarClassFilter(buttonProps.type)}
            backgroundClass={buttonProps.backgroundClass}
          >
            {t(buttonProps.label)}
          </SortingButton>
        ))}
      </div>

      <div className={styles.EtherOrdersDataControl}>
        <div className={styles.leftSide}>
          <BestOrderIcon
            className={classNames(
              styles.sortIcon,
              {
                [styles.sortByEfficiencyAsc]: sortType.sortBy === "efficiency",
              },
              []
            )}
            onClick={() => handleSort("efficiency")}
          />
          {/* {sortingButtonsData.map((buttonProps) => (
            <SortingButton
              Icon={buttonProps.Icon}
              active={filterType === buttonProps.type}
              key={buttonProps.type}
              onClick={() => handleFilter(buttonProps.type)}
            >
              {t(buttonProps.label)}
            </SortingButton>
          ))} */}
        </div>

        <div className={styles.rightSide}>

          {sortType.sortBy === "distanceToOrder" && sortType.sortOrder === "asc" ? (
            <DistanceIconDown
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByDistanceAsc]: sortType.sortBy === "distanceToOrder",
                },
                []
              )}
              onClick={() => handleSort("distanceToOrder")}
            />
          ) : (
            <DistanceIcon
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByDistanceDesc]: sortType.sortBy === "distanceToOrder",
                },
                []
              )}
              onClick={() => handleSort("distanceToOrder")}
            />
          )}
          {sortType.sortBy === "priceEstimated" && sortType.sortOrder === "asc" ? (
            <PriceIconUp
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByPriceAsc]: sortType.sortBy === "priceEstimated",
                },
                [],
              )}
              onClick={() => handleSort("priceEstimated")}
            />
          ) : (
            <PriceIcon
              className={classNames(
                styles.sortIcon,
                {
                  [styles.sortByPriceDesc]: sortType.sortBy === "priceEstimated",
                },
                [],
              )}
              onClick={() => handleSort("priceEstimated")}
            />
          )}
        </div>

      </div>

      {isPopupVisible &&
        <WagonInfoPopup
          showPopup={isPopupVisible}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          ref={popupRef}
        />}
    </>
  );
};

export default EtherOrdersDataControl;
