import { useState, useEffect } from "react";
import styles from "./styles.module.scss";

export const BlackButton = ({ children, className, withTimer, ...otherProps }) => {
  const { disabled, onClick } = otherProps;
  const [timer, setTimer] = useState(withTimer ? 10 : 0);
  const [isDisabled, setIsDisabled] = useState(!!withTimer);

  useEffect(() => {
    if (withTimer && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsDisabled(false);
    }
  }, [withTimer, timer]);

  return (
    <button
      {...otherProps}
      className={`${styles.blackButton} ${className} ${isDisabled ? styles.blackButtonDisabled : ""}`}
      disabled={isDisabled || disabled}
      onClick={onClick}
    >
      {withTimer && isDisabled ? `${children} (${timer})` : children}
    </button>
  );
};
