import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "src/shared/ui/Modal";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { Button } from "@mui/material";
import "./styles.scss";
import { t } from "i18next";

const MessageButton = ({ daysDifference, inspectionInfo }) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const handleRedirect = (path) => {
    navigate(path);
    closeModalHandler();
  };
  const messageList = t("message_list");
  const messageItems = messageList.split("&");

  return (
    <>
      {inspectionInfo?.status === 0 ? (
        <div className="under_review" onClick={() => handleRedirect("/cars")}>
          {t("TO_under_review")}
        </div>
      ) : daysDifference < 0 ? (
        <div className="status-label-message" onClick={openModalHandler}>
          {t("car_locked")}
          <div>{t("message_button")}</div>
        </div>
      ) : (
        daysDifference < 8 && (
          <div className="status-label-message" onClick={openModalHandler}>
            {t("message_button")}
            <div>{t("days_until_lock", { days: daysDifference })}</div>
          </div>
        )
      )}

      <Modal isOpen={openModal} noPadding onClose={closeModalHandler}>
        <div className="order-pref-container">
          <div className="close-block">
            <CloseIcon className="close-icon" onClick={closeModalHandler} />
          </div>
          <h1>{t("message_button")}</h1>

          <div className="message-block">
            <h2>{t("message_title")}</h2>
            <ul>
              {messageItems?.map((item, index) => (
                <li key={index}>{item.trim()}</li>
              ))}
            </ul>
            <p>{t("message_footer")}</p>
            <div className="phone-number-container">
              <h3>
                <a href="tel:0675188600" className="phone-number">📞 067-518-86-00</a>
              </h3>
              <h3>
                <a href="tel:0504573233" className="phone-number">📞 050-457-32-33</a>
              </h3>
            </div>
          </div>
          <div className="confirm_block">
            <Button className="confirm_btn btn" onClick={() => handleRedirect("/cars")}>
              {t("pass_to_TO")}
            </Button>
            <Button className="customer_service_btn btn" onClick={() => handleRedirect("/contactus")}>
              {t("customer_service")}
            </Button>
            <Button className="btn cancel_btn" onClick={closeModalHandler}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal >
    </>
  );
};

export default MessageButton;
