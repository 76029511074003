import { memo, useState } from "react";
import CustomMap from "src/components/CustomMap";
import { classNames } from "src/shared/lib/classNames/classNames";
import styles from "./styles.module.scss";

const EtherOrderMap = (props) => {
  const { destinationPoints, polylinePositions } = props;
  const driverCoordinates = JSON.parse(localStorage.getItem("coords"));

  const [mapLoaded, setMapLoaded] = useState(false);
  const [zoomFullRoute, setZoomFullRoute] = useState(false);

  return (
    <div className={styles.EtherOrderMap}>

      <div className={styles.EtherOrderMap}>
        <div className={styles.mapContainer}>
          {driverCoordinates && (
            <CustomMap
              calculateZoomByPoints={true}
              className={classNames(styles.map, { [styles.showMap]: !mapLoaded }, ["orderMap"])}
              coordsDriver={[driverCoordinates.longitude, driverCoordinates.latitude]}
              disabledFunctions={{
                disableSeparateRoute: true,
                disableButtons: true,
                disableGlueCar: true,
                disabledSpeed: true,
                disabledNavigation: true,
                disableRadius: true,
                disableInteractive: false,
                disableCircle: true,
              }}
              mapLoaded={mapLoaded}
              mapPoints={destinationPoints}
              polylinePositions={polylinePositions}
              setMapLoaded={setMapLoaded}
              setZoomFullRoute={setZoomFullRoute}
              zoomFullRoute={zoomFullRoute}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (prevProps.destinationPoints.length !== nextProps.destinationPoints.length) {
    return false;
  }

  return prevProps.destinationPoints.every((prevPoint, index) => {
    const nextPoint = nextProps.destinationPoints[index];
    return (
      prevPoint.name === nextPoint.name &&
      prevPoint.lat === nextPoint.lat &&
      prevPoint.lon === nextPoint.lon &&
      prevPoint.pointId === nextPoint.pointId &&
      prevPoint.mainText === nextPoint.mainText &&
      prevPoint.secondaryText === nextPoint.secondaryText
    );
  });
};
export default memo(EtherOrderMap, areEqual);
