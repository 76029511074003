import React from "react";
import { t } from "i18next";

import { getValuteSymbol } from "src/services/getValuteSymbol";

import { ReactComponent as CreditCard } from "src/images/credit-card.svg";
import { ReactComponent as Cashback } from "src/images/cashback.svg";

import styles from "./styles.module.scss";

const FinalOrderMenu = (props) => {
  const { order } = props;

  const orderPayDetails = [
    {
      name: t("total_amount"),
      value: `${order?.priceEstimated + order?.compensation} ${getValuteSymbol(order.currency)}`,
    },
    {
      name: t("pending_quote"),
      value: `${order?.pendingsQuote} ${getValuteSymbol(order.currency)}`,
    },
    {
      name: t("total_trip_amount"),
      value: `${order?.totalQuote + order?.compensation} ${getValuteSymbol(order.currency)}`,
    },
  ];
  return (
    <div className={styles.finalOrderMenuWrap}>
      <div className={styles.finalOrderRow}>
        <h2>{t("passenger_pays")}</h2>
        <div className={styles.finalPrice}>
          {order?.totalQuote}
          &nbsp;{getValuteSymbol(order.currency)}
        </div>
      </div>
      <div className={styles.finalOrderRow}>
        <h2>{t("company_reimburses")}</h2>
        <div className={styles.companyReimburses}>
          {order?.compensation}
          &nbsp;{getValuteSymbol(order.currency)}
        </div>
      </div>
      <div className={styles.line} />
      <ul className={styles.finalOrderBonusesDetails}>
        {orderPayDetails.map((item, index) =>
          item.name ? (
            <li key={`${item.name}_${index}`}>
              <span>{item.name}</span>
              <span>+{item.value}</span>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  );
};

export default FinalOrderMenu;
