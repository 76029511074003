import { memo, useMemo } from "react";
import { useSwipeable } from "react-swipeable";

import { t } from "i18next";
import { cancelDelayForOrderRequest } from "src/config";

import useCountdownTimer from "src/components/CountdownTimer";

import { getValuteSymbol } from "src/services/getValuteSymbol";
import { metersToKm } from "src/services/metersToKm";
import { secondsToMinSec } from "src/services/timeConvertor";
import { formatTimeToHourMinute } from "src/services/timer/formatTimeToHourMinute";

import { classNames } from "src/shared/lib/classNames/classNames";

import { ReactComponent as CarIcon } from "src/images/car.svg";
import { ReactComponent as Cashback } from "src/images/cashback.svg";
import { ReactComponent as Clock } from "src/images/clock-v2.svg";
import { ReactComponent as CloseWithRound } from "src/images/close-with-round.svg";
import { ReactComponent as CreditCard } from "src/images/credit-card.svg";
import { ReactComponent as Passanger } from "src/images/passeger.svg";
import { ReactComponent as Wallet } from "src/images/wallet.svg";

import styles from "./styles.module.scss";
import HighDemandColor from "src/components/HighDemandColor";

const ModalOrderHeaderComponent = (props) => {
  const {
    order,
    currentClassAuto,
    orderDuration,
    onHeaderClick,
    setOpenFullModal,
    declineIncomingOrder,
    missedOrder,
    driverCancelOrder,
  } = props;

  const handlers = useSwipeable({
    onSwipedDown: () => setOpenFullModal(false),
    onSwipedUp: () => setOpenFullModal(true),
    // Define delta before a swipe is triggered
    delta: 5,
    // Prevents scroll during swipe
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false,
  });

  const timeDurations = secondsToMinSec(orderDuration);
  const readHeader = missedOrder || driverCancelOrder;
  const highDemandColor = "#000000";

  const timeToFinishOrder = useCountdownTimer({
    order,
    declineIncomingOrder,
    disabled: readHeader,
  });

  // calculate order time to percents
  const percentByTime = useMemo(() => {
    if (!timeToFinishOrder) {
      return 0;
    }
    return 100 - ((timeToFinishOrder - 1000) * 100) / (cancelDelayForOrderRequest - 1000);
  }, [timeToFinishOrder]);

  const firstItem = () => {
    switch (true) {
      case missedOrder:
        return { icon: <CloseWithRound />, text: t("miss") };
      case driverCancelOrder:
        return { icon: <CloseWithRound />, text: t("canceled") };
      default:
        return { icon: <CarIcon />, text: t(currentClassAuto?.name) };
    }
  };

  const distanceToClient = () => {
    if (order.distanceToClientMeter > 1000) {
      const km = metersToKm(order.distanceToClientMeter, {
        calculateLowerNum: 10,
        calculateHigherNum: 10,
      });
      return `${km} ${t("km")}`;
    }
    return `${order?.distanceToClientMeter?.toFixed(0)} ${t("m")}`;
  };

  const topHeaderElements = [
    firstItem(),
    { icon: <Passanger />, text: distanceToClient() },
    {
      icon: <Clock />,
      text: timeDurations,
    },
  ];

  const pricesElements = [
    { icon: <CreditCard />, text: order?.pricePaid },
    { icon: <Cashback />, text: order?.bonusPaid + order?.compensation },
  ];

  return (
    <div
      className={classNames(styles.orderHeader, { [styles.missedHeader]: readHeader }, [])}
      onClick={onHeaderClick}
      {...handlers}
    >
      {!readHeader && (
        <div className={styles.orderHeaderLoadingWrap}>
          <div
            className={classNames(
              styles.orderHeaderLoading,
              { [styles.preOrderLoading]: order?.preOrderTime },
              [],
            )}
            style={{ width: `${percentByTime}%` }}
          />
        </div>
      )}
      <ul className={styles.topHeaderElements}>
        {topHeaderElements.map((item, index) => {
          const lastItem = index === topHeaderElements.length - 1;
          return (
            <li
              className={`${styles.headerElement} ${styles.topHeaderElement} ${
                lastItem && styles.headerLastElement
                }`}
              key={`${item.text}_${index}`}
            >
              <div className={styles.topHeaderItem}>
                {item.icon}
                <span>{item.text}</span>
              </div>

              {!lastItem && (
                <div className={styles.separatorWrap}>
                  <div className={styles.separatorRoundElement} />
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <div className={styles.orderPrice}>
        <div className={styles.goldenItems}>
          <Wallet />
          {order?.highDemandColor && <HighDemandColor highDemandColor={order?.highDemandColor} />}
          <span>
            {order?.priceEstimated + order?.bonusPaid + order?.compensation}
            &nbsp;{getValuteSymbol(order.currency)}
          </span>
        </div>

        <div className={styles.priceDetailWrap}>
          (
          {pricesElements.map((item, index) => {
            const lastItem = index === pricesElements.length - 1;
            return (
              <li
                className={`${styles.headerElement} ${lastItem && styles.headerLastElement}`}
                key={`${item.text}_${index}`}
              >
                <div className={styles.priceDetailItem}>
                  {item.icon}
                  <span>{item.text}</span>
                </div>

                {!lastItem && (
                  <div className={styles.separatorWrap}>
                    <div className={styles.separatorStraightElement} />
                  </div>
                )}
              </li>
            );
          })}
          )
        </div>
      </div>
      {order?.preOrderTime && (
        <div className={styles.timeDelivary}>
          {t("car_delivery_time", { time: formatTimeToHourMinute(order.preOrderTime) })}
        </div>
      )}
    </div>
  );
};

export default memo(ModalOrderHeaderComponent);
