import { useEffect, useState } from "react";
import { classNames } from "src/shared/lib/classNames/classNames";
import { Button } from "src/shared/ui/Buttons/DefautlButton/button";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import styles from "./styles.module.scss";
import PriceSliderFilter from "src/shared/ui/PriceSliderFilter";
import CarClassesFilter from "src/shared/ui/CarClassesFilter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { carClasses } from "src/shared/constants";
import PricePerKmSliderFilter from "src/shared/ui/PricePerKmSliderFilter";

const carClassFilterButtonsSet = new Set([
  { type: carClasses.STANDARD, label: "standard", backgroundClass: 'economyHighlighting' },
  { type: carClasses.COMFORT, label: "comfort", backgroundClass: 'standardHighlighting' },
  { type: carClasses.BUSINESS, label: "business", backgroundClass: 'comfortHighlighting' },
  { type: carClasses.MINIVAN, label: "minivan", backgroundClass: 'minivanHighlighting' },
  { type: carClasses.WAGON, label: "wagon", backgroundClass: 'wagonHighlighting' },
]);

const getFilteredCarClassButtonsSet = (defaultCarClassId) => {
  const exclusions = {
    [carClasses.STANDARD]: new Set([carClasses.COMFORT, carClasses.BUSINESS, carClasses.MINIVAN]),
    [carClasses.COMFORT]: new Set([carClasses.BUSINESS, carClasses.MINIVAN]),
    [carClasses.BUSINESS]: new Set([carClasses.MINIVAN]),
    [carClasses.MINIVAN]: new Set([carClasses.BUSINESS]),
  };

  return new Set(
    [...carClassFilterButtonsSet].filter(
      (button) => !exclusions[defaultCarClassId]?.has(button.type)
    )
  );
};

const AutoAcceptOptions = ({
  onCloseModal,
  orderSearchFilter,
  updateOrderSearchFilter,
  getDriverInfo,
  currency
}) => {

  const { t } = useTranslation();
  const avtos = useSelector((state) => state.cars.avtos);
  const defaultCar = avtos.find((car) => car.isDefault);
  const defaultCarClassId = defaultCar ? defaultCar.classId : carClasses.STANDARD;
    const filteredCarClassFilterButtonsSet = getFilteredCarClassButtonsSet(defaultCarClassId);
  const [newOrderSearchFilter, setNewOrderSearchFilter] = useState(orderSearchFilter);
  const allCarClassIds = new Set([...filteredCarClassFilterButtonsSet].map((btn) => btn.type));
  const [activeCarClassIds, setActiveCarClassIds] = useState(() => {
    return new Set([...allCarClassIds].filter(id => !newOrderSearchFilter?.skipCarClassIds.includes(id)));
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const getStoredValues = () => {
    const storedValues = JSON.parse(localStorage.getItem("pricePerKmSliderFilter")) || {};
    return {
      minKmPrice: storedValues.minKmPrice ?? 0,
    };
  };

  const getStoredminPrice = () => {
    const storedValues = JSON.parse(localStorage.getItem("priceSliderFilter")) || {};
    return {
      minPrice: storedValues.minPrice ?? 50,
    };
  };

  const minKmPrice = newOrderSearchFilter?.minKmPrice ?? getStoredValues().minKmPrice;
  const minPrice = newOrderSearchFilter?.minPrice ?? getStoredminPrice().minPrice;

  useEffect(() => {
    const inactiveCarClassIds = [...allCarClassIds].filter(id => !activeCarClassIds.has(id));

    setNewOrderSearchFilter(prevFilter => ({
      ...prevFilter,
      skipCarClassIds: inactiveCarClassIds
    }));
  }, [activeCarClassIds]);

  const handleUpdateFilter = async () => {
    try {
      setIsDisabled(true);
      await updateOrderSearchFilter(newOrderSearchFilter);

      getDriverInfo();
    } catch (error) {
      console.error("Filter update error:", error);
    } finally {
      setIsDisabled(false);
    }
    onCloseModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.closeBlock}>
        <CloseIcon className={styles.closeIcon} onClick={onCloseModal} />
      </div>
      <h1>{t("order_filter")}</h1>
      <h2 className={styles.filterTitle}>{t("filter_title")}</h2>
      <ul className={styles.filterList}>
          <li className={styles.filterItem}>
            <h2>{t("car_class_filter")}</h2>
            <div className={styles.carClassesFilter}>
              <CarClassesFilter
                activeCarClassIds={activeCarClassIds}
                setActiveCarClassIds={setActiveCarClassIds}
                defaultCarClassId={defaultCarClassId}
                carClassFilterButtonsSet={filteredCarClassFilterButtonsSet}
              />
            </div>
          </li>

        <li className={styles.filterItem}>
          <h2>{t("price_slider_filter")}</h2>
          <PriceSliderFilter
            minPrice={minPrice}
            setNewOrderSearchFilter={setNewOrderSearchFilter}
            currency={currency}
            isActiveButton={!newOrderSearchFilter?.minPrice}
          />
        </li>

        <li className={styles.filterItem}>
          <h2>{t("price_slider_filter_per_km")}</h2>
          <PricePerKmSliderFilter
            minKmPrice={minKmPrice}
            setNewOrderSearchFilter={setNewOrderSearchFilter}
            currency={currency}
            isActiveButton={!(newOrderSearchFilter?.minKmPrice || newOrderSearchFilter?.maxKmPrice)}
          />
        </li>
      </ul>

      <div className={styles.confirmBlock}>
        <Button
          className={classNames(styles.confirmBtn, { [styles.btnDisabled]: isDisabled }, [])}
          disabled={isDisabled}
          onClick={handleUpdateFilter}
        >
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
};

export default AutoAcceptOptions;
