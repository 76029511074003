import styles from "./styles.module.scss";
import { ReactComponent as DoubleArrowDark } from "src/images/DoubleArrowDark.svg";
import "./styles.module.scss";

const HighTariffHexagons = (props) => {
  const { isActiveHexagons, setIsActiveHexagons } = props;

  return (
    <>
      <button onClick={setIsActiveHexagons} className={styles.button}>
        <DoubleArrowDark
          className={`${styles.orderHeaderDoubleArrow} ${isActiveHexagons? styles.greenButton :styles.redButton }`}
        />
      </button>
    </>
  );
};

export default HighTariffHexagons;
