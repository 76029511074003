import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import SortingButton from "src/pages/EtherOrdersPage/components/EtherOrdersDataControl/components/SortingButton";

const PricePerKmSliderFilter = ({ minKmPrice, setNewOrderSearchFilter, currency, isActiveButton }) => {
    const [min, setMin] = useState(minKmPrice);
    const sliderRef = useRef(null);
    const [isActiveAllButton, setIsActiveAllButton] = useState(isActiveButton);
    const { t } = useTranslation();

    useEffect(() => {
        updateTrackWidth();
    }, [min]); 

    useEffect(() => {
        if (isActiveAllButton) {
            setNewOrderSearchFilter((prev) => ({
                ...prev,
                minKmPrice: null,
            }));
        } else {
            setNewOrderSearchFilter((prev) => ({
                ...prev,
                minKmPrice: min,
            }));
        }
    }, [isActiveAllButton]);

    const updateTrackWidth = () => {
        if (sliderRef.current) {
            const percent = (min / 100) * 100;
            sliderRef.current.style.setProperty("--progress-width", `${percent}%`);
        }
    };

    const handleMinChange = (e) => {
        const value = Number(e.target.value);
        setMin(value);
        localStorage.setItem("pricePerKmSliderFilter", JSON.stringify({ minKmPrice: value }));

        setNewOrderSearchFilter((prev) => ({
            ...prev,
            minKmPrice: value
        }));
    };
    return (
        <div className={styles.container}>
            <span className={`${styles.value} ${isActiveAllButton ? styles.disabled : ""}`}>{min} {currency} </span>

            <div className={styles.controlContainer}>
                <div>
                    <SortingButton
                        active={isActiveAllButton}
                        key="All"
                        onClick={() => setIsActiveAllButton(!isActiveAllButton)}
                        size="filterStyle"
                    >
                        {t("all")}
                    </SortingButton>
                </div>

                <input
                    ref={sliderRef}
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={min}
                    onChange={handleMinChange}
                    className={`${styles.slider} ${isActiveAllButton ? styles.disabled : ""}`}
                    disabled={isActiveAllButton}
                />
            </div>
        </div >
    );
};

export default PricePerKmSliderFilter;
