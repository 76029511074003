import { useEffect, useRef } from "react";
import maplibregl from "!maplibre-gl";

import styles from "./styles.module.scss";

export const useDrawRounds = (props) => {
  const { rounds, map, mapLoaded, styleIsLoad, isActiveHexagons } = props;
  const prevRoundsRef = useRef(new Map());

  useEffect(() => {
    if (!mapLoaded || !map.current || !styleIsLoad) return;

    const mapInstance = map.current;
    const prevRounds = prevRoundsRef.current;

    if (isActiveHexagons && rounds?.areas.size) {
      rounds.areas.forEach((area, id) => {
        if (!area.polygon || !Array.isArray(area.polygon) || !area.polygon.length) return;
        if (area.value <= 1) return;

        const layerId = `hexagon-${id}`;
        const sourceId = `hexagon-source-${id}`;

        if (mapInstance.getLayer(layerId)) return;

        const color = area.color || "rgba(153, 255, 153, 0.5)";
        const borderColor = "rgb(2, 2, 2)";
        const coordinates = area.polygon.map(({ x, y }) => [x, y]);

        const geojson = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [coordinates],
              },
            },
          ],
        };

        mapInstance.addSource(sourceId, {
          type: "geojson",
          data: geojson,
        });

        mapInstance.addLayer({
          id: layerId,
          type: "fill",
          source: sourceId,
          layout: {},
          paint: {
            "fill-color": color,
            "fill-opacity": 0.5,
            "fill-outline-color": borderColor,
          },
        });

        const hexagonElement = document.createElement("div");
        hexagonElement.classList.add(styles.hexagon);

        const labelElement = document.createElement("div");
        labelElement.classList.add(styles.label);
        labelElement.textContent = "x" + area.value;

        const colorWithFullOpacity = color.replace(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*[\d.]*\)/, "rgb($1, $2, $3)");
        labelElement.style.backgroundColor = colorWithFullOpacity;

        hexagonElement.appendChild(labelElement);

        const centerCoordinates = [Number(area.lon), Number(area.lat)];

        const newRound = new maplibregl.Marker({
          element: hexagonElement,
          rotationAlignment: "map",
          pitchAlignment: "map",
        });

        prevRounds.set(id, {
          mapElement: newRound,
          layerId,
          sourceId,
        });

        newRound.setLngLat(centerCoordinates).addTo(map.current);
      });
    } else {
      prevRounds.forEach((prevArea, id) => {
        if (prevArea.mapElement) {
          prevArea.mapElement.remove();
        }

        if (mapInstance.getLayer(prevArea.layerId)) {
          mapInstance.removeLayer(prevArea.layerId);
        }

        if (mapInstance.getSource(prevArea.sourceId)) {
          mapInstance.removeSource(prevArea.sourceId);
        }
      });

      prevRounds.clear();
    }
  }, [map, mapLoaded, rounds, styleIsLoad, isActiveHexagons]);
};
