import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";

import maplibregl from "!maplibre-gl";

import "maplibre-gl/dist/maplibre-gl.css";
import { t } from "i18next";

import useMapNavigation from "./hooks/useMapNavigation";
import { calculatePointOnLine } from "./helpers/calculatePointOnLine";
import { pulsateCircle } from "./animations/pulsateCircle";
import { classNames } from "src/shared/lib/classNames/classNames";

import "./styles.scss";
import useCreateMap from "./hooks/useCreateMap";
import { useChangeMapView } from "./hooks/useChangeMapView";
import OtherNavigation from "src/pages/Order/components/OtherNavigation";
import { ReactComponent as Navigation } from "src/images/Navigation.svg";
import useMarkerInCenter from "./hooks/useMarkerInCenter";
import { bearingAccuracyMeters, defaultZoom } from "./constants";
import calculateZoomLevel from "./helpers/calculateZoomLevel";
import createGeoJSONCircle from "./helpers/createGeoJSONCircle";
import getCenterAndZoom from "./helpers/getCenterAndZoom";
import { splitPolylinePositions } from "./helpers/splitPolylinePositions";
import useBuildFullRoute from "./hooks/useBuildFullRoute";
import useDrawMarker from "./hooks/useDrawMarker";
import useDrawPolygons from "./hooks/useDrawPolygons";
import useMapPopups from "./hooks/useMapPopups";
import { ReactComponent as GpsLogo } from "../../images/map-icons/gps.svg";
import { setIsMapMoving } from "../../pages/Map/map.actions";
import { useDrawRounds } from "./hooks/useDrawRounds/useDrawRounds";

const CustomMap = (props) => {
  const {
    coordsDriver,
    atwork,
    className,
    polylinePositions,
    lightMode,
    mapPoints,
    radius,
    zoomFullRoute,
    mapLoaded,
    setMapLoaded,
    customMapZoom = calculateZoomLevel(radius),
    setZoomFullRoute = () => null,
    setIsMapMoving,
    isMapMoving,
    fitBoundsParams = null,
    speed,
    calculateZoomByPoints,
    resetZoom,
    showPopup,
    setResetZoom = () => null,
    onNavigationClick = () => null,
    onMapProviderBtn = () => null,
    setMarkerCenterCoords = () => null,
    callBackReturnMap = () => null,
    disabledFunctions = {},
    drawPolygonProps,
    rounds = null,
    isActiveHexagons,
  } = props;

  const map = useRef(null);
  const drawMarkerRef = useRef(false);
  const {
    disableSeparateRoute = false,
    disableButtons = false,
    disableGlueCar = false,
    disabledSpeed = false,
    disabledNavigation = false,
    disabledCarMarker = false,
    disableCircle = false,
    disableRadius = false,
    disableInteractive = false,
    disabled3DView = true,
    disabledMarkerInCenter = true,
    disabledDrawPolygons = true,
  } = disabledFunctions;

  const orderStage = localStorage.getItem("orderStage");
  // get coords driver on polyline or took real coords
  const markerCoordsOnLine = useMemo(() => {
    // create coords for marker
    let markerCoords = coordsDriver;
    // stick car on polyline
    if (coordsDriver && polylinePositions && !disableGlueCar) {
      const result = calculatePointOnLine(map, coordsDriver, polylinePositions);

      if (result?.coords) {
        markerCoords = result.coords;
      }
    }

    return markerCoords;
  }, [coordsDriver, polylinePositions, disableGlueCar]);

  const [navigationCoords, setNavigationCoords] = useState(markerCoordsOnLine);
  const [showMyLocation, setShowMyLocation] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [navigationBearing, setNavigationBearing] = useState(0);
  const [styleIsLoad, setStyleIsLoad] = useState(false);

  const animationRadiusRef = useRef(null);

  const mapContainer = useRef(null);
  const carMarkerRef = useRef(null);
  const centerPointMarkerRef = useRef(null);
  const blackPointRef = useRef(null);
  const currentTheme = lightMode ? "lightTheme" : "darkTheme";
  const geoJSONCircle = createGeoJSONCircle(coordsDriver, radius);

  const mapTheme = "/map-files/dark-map-styles.json";

  // route position
  const prevPolylinePositions = useRef(null);

  const handleMapActions = useCallback(() => {
    // Disable tracking when the map is swiped
    setShowMyLocation(false);
  }, []);

  // change map view between 2D and 3Dh
  const { mapView, changeMapView } = useChangeMapView({ map, mapLoaded });

  // create map
  useCreateMap({
    map,
    mapContainer,
    mapTheme,
    coordsDriver,
    customMapZoom,
    setIsMapMoving,
    orderStage,
    disableCircle,
    geoJSONCircle,
    atwork,
    disabledFunctions,
  });

  // // build full route
  useBuildFullRoute({
    polylinePositions,
    styleIsLoad,
    map,
    prevPolylinePositions,
    currentTheme,
    blackPointRef,
    coordsDriver,
    mapPoints,
    mapLoaded,
    disableSeparateRoute,
  });

  // // Map Navigation
  useMapNavigation({
    map,
    navigation,
    navigationBearing,
    navigationCoords,
    disabledNavigation,
    defaultZoom,
    zoomFullRoute,
    setNavigation,
    speed,
  });

  // // add car marker and calculate rotation
  useDrawMarker({
    map,
    carMarkerRef,
    polylinePositions,
    bearingAccuracyMeters,
    coordsDriver: markerCoordsOnLine,
    disabledCarMarker,
    setNavigationCoords,
    drawMarkerRef: drawMarkerRef.current,
    bearingCallBack: (bearing) => setNavigationBearing(bearing),
  });

  // add marker on center map which return coords
  useMarkerInCenter({
    map,
    mapLoaded,
    centerPointMarkerRef,
    coordsDriver,
    disabledMarkerInCenter,
    setMarkerCenterCoords,
  });

  // allow draw polygons by coords
  useDrawPolygons({ map, disabledDrawPolygons, drawPolygonProps, mapLoaded });

  // allow draw rounds by center coords and radius
  useDrawRounds({ rounds, map, mapLoaded, styleIsLoad, isActiveHexagons });

  // change and animation radius
  useEffect(() => {
    if (!map.current) {
      return;
    }

    const circleLayer = map.current.getLayer("circle-layer");
    if (circleLayer) {
      map.current.once("idle", () => {
        map.current.setPaintProperty("circle-layer", "fill-color", atwork ? "#1DB960" : "#757575");

        map.current.setPaintProperty("circle-layer-outline", "line-color", atwork ? "#1DB960" : "#9E9E9E");

        if (atwork && mapLoaded && !orderStage) {
          const stopAnimation = pulsateCircle(map);
          animationRadiusRef.current = stopAnimation;
        } else if (animationRadiusRef.current) {
          animationRadiusRef.current();
        }
      });
    }

    return () => {
      if (animationRadiusRef.current) {
        animationRadiusRef.current();
      }
    };
  }, [atwork, mapLoaded, orderStage]);

  // set new radius and change zoom map
  useEffect(() => {
    if (!map.current) {
      return;
    }

    const circleSource = map.current.getSource("circle-source");
    if (circleSource && circleSource.setData) {
      circleSource.setData(createGeoJSONCircle(coordsDriver, radius));

      if (!isMapMoving) {
        map.current.easeTo({
          center: coordsDriver,
          zoom: calculateZoomLevel(radius),
        });
      }
    }
  }, [radius, coordsDriver, isMapMoving]);

  // zoom map to full route view
  useEffect(() => {
    if (map.current && polylinePositions && zoomFullRoute) {
      handleMapActions();
      const correctPolyPos = polylinePositions.map((item) => [item[1], item[0]]);

      map.current.resize();

      const fitBoundsOptions = { padding: { top: 40, bottom: 10, left: 10, right: 10 }, ...fitBoundsParams };

      const bounds = correctPolyPos.reduce(
        (bounds, coord) => {
          return bounds.extend(coord);
        },
        new maplibregl.LngLatBounds(correctPolyPos[0], correctPolyPos[0]),
      );

      map.current.fitBounds(bounds, fitBoundsOptions);

      setZoomFullRoute(false);
    }
  }, [fitBoundsParams, handleMapActions, polylinePositions, setZoomFullRoute, zoomFullRoute]);

  // show my location
  useEffect(() => {
    if (showMyLocation && map.current && !zoomFullRoute) {
      const deactivateMyLocation = () => {
        setShowMyLocation(false);
      };
      map.current.resize();
      map.current.flyTo({ center: coordsDriver, zoom: defaultZoom });
      map.current.on("wheel", handleMapActions);
      map.current.on("drag", handleMapActions);

      map.current.on("moveend", deactivateMyLocation);

      // Clean up event listeners when the component unmounts or when tracking changes
      return () => {
        if (map.current) {
          map.current.off("wheel", handleMapActions);
          map.current.off("drag", handleMapActions);
          map.current.off("moveend", deactivateMyLocation);
        }
      };
    }
  }, [coordsDriver, showMyLocation, zoomFullRoute, handleMapActions]);

  // reset zoom
  useEffect(() => {
    if (resetZoom && map.current && mapLoaded && coordsDriver && radius) {
      map.current.resize();
      map.current.flyTo({
        center: coordsDriver,
        zoom: calculateZoomLevel(radius),
      });
      setResetZoom(false);
    }
  }, [resetZoom, mapLoaded, coordsDriver, radius, setResetZoom]);

  // check if map loaded
  useEffect(() => {
    map.current.on("load", function () {
      setMapLoaded(true);
    });
  }, [setMapLoaded]);

  // stop marker animation at first rendering
  useEffect(() => {
    drawMarkerRef.current = true;
  }, []);

  // calculate zoom and center if we already have points
  useEffect(() => {
    if (calculateZoomByPoints && map.current) {
      const paddingForPopup = showPopup ? 90 : 15;

      const driverCoordinates = [coordsDriver[1], coordsDriver[0]];
      const segmentedPolyline = splitPolylinePositions(polylinePositions);
      const locationsToConsider = [driverCoordinates, ...segmentedPolyline];

      getCenterAndZoom(locationsToConsider, map, paddingForPopup);
    }
  }, [calculateZoomByPoints, coordsDriver, polylinePositions, showPopup]);

  // check if style map loaded
  useEffect(() => {
    map.current.on("style.load", function () {
      setStyleIsLoad(true);
    });
  }, [setStyleIsLoad]);

  //return map
  useEffect(() => {
    if (map.current && mapLoaded) {
      callBackReturnMap(map);
    }
  }, [callBackReturnMap, mapLoaded]);

  return (
    <div
      className="maplibre-map"
      ref={mapContainer}
      style={{ width: "100%", height: "100%", marginBottom: "-1px" }}
    >
      {/* Gps */}
      {!disableButtons && (
        <button
          className={`location-button ${showMyLocation ? "location-button-tracking" : ""}`}
          onClick={() => {
            setShowMyLocation(!showMyLocation);
            setZoomFullRoute(false);
          }}
        >
          <GpsLogo className="location-image" />
        </button>
      )}
      {/* 3D view button */}
      {/*       {!disabled3DView && (
        <button className="view-3d-button" onClick={changeMapView}>
          {mapView}
        </button>
      )} */}
      {/* Speed */}
      {!disabledSpeed && speed && (
        <div className="speed">
          <span>{speed}</span>
          <span>{t("km_per_hour")}</span>
        </div>
      )}

      {!disabledNavigation && orderStage && (
        <>
          {/* Navigation button */}
          <Navigation
            className={`map-navigation ${navigation ? "map-navigation active" : ""}`}
            onClick={() => {
              setNavigation(!navigation);
              onNavigationClick(navigation);
            }}
          />
          {/* button with different navigation */}
          <OtherNavigation
            coordsDriver={coordsDriver}
            mapPoints={mapPoints}
            onMapProviderBtn={onMapProviderBtn}
          />
        </>
      )}
    </div>
  );
};


// TODO REMOVE CONNECT STORE HERE
const mapStateToProps = (state) => {
  return {
    lightMode: state.app.colorTheme,
    radius: state.map.radius,
    isMapMoving: state.map.isMapMoving,
    atwork: state.orders.workStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsMapMoving: (isMove) => dispatch(setIsMapMoving(isMove)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CustomMap));
