import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, axiosInstanceWithToken } from "../../axios.config";
import { apinew } from "../../config";

export const GET_AVTOS = "GET_AVTOS";
export const SET_CAR_ID = "SET_CAR_ID";
export const DEL_CAR = "DEL_CAR";
export const ADD_AVTO = "ADD_AVTO";
export const SET_EDIT_CAR_ID = "SET_EDIT_CAR_ID";
export const GET_CAR = "GET_CAR";
export const UPDATE_CAR = "UPDATE_CAR";
export const SHOW_CARS_MODAL = "SHOW_CARS_MODAL";
export const SET_CURRENT_CAR_ID = "SET_CURRENT_CAR_ID";
export const SELECTED_CAR_STATUS = "SELECTED_CAR_STATUS";
export const RESET_EDIT_CAR_STATUS = "RESET_EDIT_CAR_STATUS";
export const SHOW_MODAL_PREFS = "SHOW_MODAL_PREFS";
export const SET_NEW_PREFS = "SET_NEW_PREFS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SHOW_EXPRESS_CAR_WINDOW = "SHOW_EXPRESS_CAR_WINDOW";

export const getAvtos = createAsyncThunk("cars/getAvtos", async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstanceWithToken.get(`${apinew}/car`);

    const carsList = [...response.data.data];
    const findDefaultCar = carsList.find((car) => Boolean(car.isDefault));

    dispatch({ type: SELECTED_CAR_STATUS, payload: findDefaultCar || {} });
    dispatch(setCurrentCarId(findDefaultCar ? findDefaultCar.id : null));
    dispatch({ type: GET_AVTOS, payload: carsList });
  } catch (error) {
    console.error("Error fetching cars:", error);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Set a new default express car and delete all the cars except the newly added one
export const setDefaultExpressCar = createAsyncThunk(
  "cars/setDefaultExpressCar",
  async (carInfo, { getState, dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const avtos = getState().cars.avtos;

      if (avtos?.length > 0) {
        await Promise.all(avtos.map((car) => dispatch(delCar(car.id))));
      }

      await axiosInstance({
        method: "post",
        url: `${apinew}/car/expresscar`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: carInfo,
      });

      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const shouldShowExpressCarWindow = createAsyncThunk(
  "cars/shouldShowExpressCarWindow",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const { data } = await axiosInstance({
        method: "get",
        url: `${apinew}/profile/car-selection-window`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch({
        type: SHOW_EXPRESS_CAR_WINDOW,
        payload: data,
      });

      if (data) {
        localStorage.setItem("acceptDriverCar", true);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch({
          type: SHOW_EXPRESS_CAR_WINDOW,
          payload: false,
        });
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const disableExpressCarWindow = createAsyncThunk(
  "cars/disableExpressCarWindow",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      await axiosInstance({
        method: "put",
        url: `${apinew}/profile/car-selection-window`,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      dispatch({
        type: SHOW_EXPRESS_CAR_WINDOW,
        payload: true,
      });

      localStorage.setItem("acceptDriverCar", true);
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch({
          type: SHOW_EXPRESS_CAR_WINDOW,
          payload: true,
        });
      } else {
        return rejectWithValue(error);
      }
    }
  },
);

export const setCarId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAR_ID,
      payload: id,
    });
  };
};

export const setEditCarId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_CAR_ID,
      payload: id,
    });
  };
};

export const resetEditCarStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_EDIT_CAR_STATUS,
    });
  };
};

export const delCar = createAsyncThunk("cars/delCar", async (id, { dispatch, rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance({
      method: "delete",
      url: `${apinew}/car/${id}`,
      headers: { Authorization: `Bearer ${accessToken}` },
      data: { id },
    });

    if (response?.status === 200 || response?.status === 204) {
      dispatch({
        type: DEL_CAR,
        payload: true,
      });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addAvto = createAsyncThunk("cars/addAvto", async (carInfo, { dispatch, rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance({
      method: "post",
      url: `${apinew}/car`,
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        brandId: carInfo.brandId,
        modelId: carInfo.modelId,
        colorId: carInfo.colorId,
        govNumber: carInfo.govNumber,
        manufactureYear: carInfo.manufactureYear,
        orderPreferences: carInfo.orderPreferences,
        driverId: carInfo.driverId,
        seatCount: carInfo.seatCount,
      },
    });

    if (response?.status === 200 || response?.status === 204) {
      dispatch({
        type: ADD_AVTO,
      });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCar = createAsyncThunk(
  "cars/updateCar",
  async (carInfo, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "put",
        url: `${apinew}/car/${carInfo.id}`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          id: carInfo.id,
          brandId: carInfo.brandId,
          modelId: carInfo.modelId,
          colorId: carInfo.colorId,
          govNumber: carInfo.govNumber,
          manufactureYear: carInfo.manufactureYear,
          orderPreferences: carInfo.orderPreferences,
          driverId: carInfo.driverId,
        },
      });

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: UPDATE_CAR,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsDefault = createAsyncThunk("cars/setIsDefault", async (carInfo, { rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    await axiosInstance({
      method: "put",
      url: `${apinew}/car/${carInfo.id}/isDefault`,
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        id: carInfo.id,
      },
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCar = createAsyncThunk("cars/getCar", async (carId, { dispatch, rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(`${apinew}/car/${carId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (response?.status === 200 || response?.status === 204) {
      dispatch({
        type: GET_CAR,
        payload: response?.data.data,
      });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const showModalCars = (status) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_CARS_MODAL,
      payload: status,
    });
  };
};

export const setCurrentCarId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CAR_ID,
      payload: id,
    });
  };
};

export const selectedCarStatus = createAsyncThunk(
  "cars/selectedCarStatus",
  async (selectInfo, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axiosInstance({
        method: "put",
        url: `${apinew}/car/${selectInfo.id}`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          id: selectInfo.id,
          brandId: selectInfo.brandId,
          modelId: selectInfo.modelId,
          colorId: selectInfo.colorId,
          govNumber: selectInfo.govNumber,
          manufactureYear: selectInfo.manufactureYear,
          orderPreferences: selectInfo.orderPreferences,
          driverId: selectInfo.driverId,
        },
      });

      if (response?.status === 200 || response?.status === 204) {
        localStorage.setItem("selectedCarId", selectInfo.id);
        dispatch({
          type: SELECTED_CAR_STATUS,
          payload: selectInfo,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const showModalPrefs = (status) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PREFS,
      payload: status,
    });
  };
};

export const setNewPrefs = (prefArr) => {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_PREFS,
      payload: prefArr,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

export const clearErrorCars = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

export const CLEAR_CARS = "CLEAR_CARS";
export const clearCars = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CARS,
    });
  };
};

export const RESET_SELECTED_CAR = "RESET_SELECTED_CAR";
export const resetSelectedCar = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SELECTED_CAR,
    });
  };
};
