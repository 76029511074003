import React, { forwardRef } from "react";
import { ReactComponent as WarningIcon } from "src/images/warning-red.svg";
import { BlackButton } from "src/shared/ui/Buttons/BlackButton";
import { BorderedButton } from "src/shared/ui/Buttons/BorderedButton";

import styles from "./styles.module.scss";
import ModalNew from "src/pages/Modals/ModalNew";
import { t } from "i18next";

const WagonInfoPopup = (
  { onConfirm, onCancel, showPopup },
  ref
) => {

  const popupInner = (
    <div className={styles.wagonInfoPopup} ref={ref}>
      <WarningIcon className={styles.warningImg} />
      <h2 className={styles.title}>{t("wagon")}</h2>
      <p className={styles.wagonInfoDescription}>
        {t("wagon_description")}
      </p>
      <BlackButton onClick={() => onConfirm()} withTimer>
        {t("confirm")}
      </BlackButton>

      <BorderedButton onClick={onCancel}>
        {t("cancel")}
      </BorderedButton>
    </div>
  );

  return (
    <ModalNew
      customInnerComponent={popupInner}
      newModalContentClassName={styles.newModalContentClassName}
      newModalWrapClassName={`${styles.alertPopupWrap} ${showPopup ? styles.alertPopupWrapActive : ""}`}
    />
  );
};

export default forwardRef(WagonInfoPopup);
